import Vue from 'vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import moment from 'moment'
import request from "@/utils/request";

import '@/assets/css/global.css'
import '@/assets/css/iconfont/iconfont.css' //引入自定义图标
import '@/assets/css/theme/index.css' // 引入自定义的主题
import 'highlight.js/styles/monokai-sublime.css' //引入代码高亮的样式

import App from './App.vue'
import router from './router'
//关闭生产模式
Vue.config.productionTip = false

//自定义请求工具
Vue.prototype.$request = request
// 设置后端URL请求地址
Vue.prototype.$baseApi = process.env.VUE_APP_BASE_API

// 创建vue时间格式化
Vue.prototype.moment = moment

Vue.use(ElementUI, {size: "small"})

Vue.use(require('vue-moment'))

Vue.filter('dateFormat',function(dateStr,pattern='YYYY-MM-DD HH:mm:ss'){
    return moment(dateStr).format(pattern);
})



new Vue({
    // 将上面导入的router对象作为选项传递给Vue实例
    router,
    //渲染函数, 将App这个组件渲染为真实的DOM元素
    render: h => h(App)
}).$mount('#app')  //将渲染好的vue实例挂在到app的DOM元素上
