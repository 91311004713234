import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}



const router = new VueRouter({

  mode: 'history',  // 取消请求路径的 #

  scrollBehavior(to, from, savePosition) { // 在点击浏览器的“前进/后退”，或者切换导航的时候触发。
    if (savePosition) {
      return savePosition;
    }
  },
  routes: [
    {

      path: '/front',
      name: 'Front',
      component: () => import('../views/Front.vue'),
      children: [
        { path: 'home', name: 'FHome', meta: {auth: true, name: '系统首页' }, component: () => import('../views/front/Home') },
        { path: 'person', name: 'Person', meta: {auth: true, name: '个人信息' }, component: () => import('../views/front/Person') },
        { path: 'blogDetail', name: 'BlogDetail', meta: {auth: true, name: '博客详情' }, component: () => import('../views/front/BlogDetail') },
        { path: 'advance', name: 'Advance', meta: {auth: true, name: '最新前沿' }, component: () => import('../views/front/Advance') },
        { path: 'search', name: 'Search', meta: {auth: true, name: '搜索' }, component: () => import('../views/front/Search') },
        { path: 'activity', name: 'Activity', meta: {auth: true, name: '活动' }, component: () => import('../views/front/Activity') },
        { path: 'activityDetail', name: 'ActivityDetail', meta: {auth: true, name: '活动详情' }, component: () => import('../views/front/ActivityDetail') },
        { path: 'newBlog', name: 'NewBlog', meta: {auth: true, name: '发表博客' }, component: () => import('../views/front/NewBlog') },
      ]
    },
    { path: '/',  redirect: '/front/home'  },
    { path: '/login', name: 'Login', meta: {auth: false, name: '登录' }, component: () => import('../views/Login.vue') },
    { path: '/register', name: 'Register', meta: {auth: false, name: '注册' }, component: () => import('../views/Register.vue') },
    { path: '*', name: 'NotFound', meta: {auth: false, name: '无法访问' }, component: () => import('../views/404.vue') },

    { path: '/test', name: 'LoginTest', meta: {auth: false, name: '登录测试' }, component: () => import('../views/test/loginTest') },
  ]
    }

)

// 里面的meta:{auth: false, 用一个元数据auth 来表示路由守卫, 是否需要认证.

/*
// 路由守卫
router.beforeEach((to, from, next) => {
  const isLoggedIn = store.state.isLoggedIn; // 假设你有一个全局的 store 引用
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 这个路由需要验证
    if (!isLoggedIn) {
      // 用户未登录，重定向到登录页面或显示提示
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next() // 确保一定要调用 next()
    }
  } else {
    next() // 确保一定要调用 next()
  }
})*/
export default router
