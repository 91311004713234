import axios from 'axios'
import {Notification, MessageBox, Message} from 'element-ui'
import router from '@/router'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'


// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 10000
})


// request拦截器
service.interceptors.request.use(config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
        config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})


// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code ||  200
        // 获取错误信息
        const msg = errorCode[code] || res.data.msg || errorCode['default']
        if (code === 401) {
            MessageBox.confirm('需要登录才能访问', '提示', {
                    confirmButtonText: '登录',
                    type: 'warning'
                }
            ).then(() => {
                localStorage.setItem('logUrl', router.currentRoute.fullPath);
                router.push({
                    path: '/login'
                });

            }).catch(() => {

            })
             return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
        } else if (code === 500) {
            Message({
                message: msg,
                type: 'error'
            })
            return Promise.reject(new Error(msg))
        } else if (code !== 200) {
            Notification.error({
                message: msg
            })
            return Promise.reject(new Error(`HTTP Error: ${code} - ${msg}`))
        }
        else {
            // 把字符串total 转换成 数字 total
            if (res.data.data && res.data.data.total) {
                res.data.data.total = parseInt(res.data.data.total)
            }

            return res.data
        }
    },
    error => {
        console.log('err' + error)
        let { message } = error
        if (message === 'Network Error') {
            message = '网络异常'
        } else if (message.includes('timeout')) {
            message = '系统接口请求超时'
        } else if (message.includes('failed with status code 500')) {
            message = '系统接口异常'
        } else if (message.includes('400')) {
            message = '请求参数错误'
        } else if (message.includes('405')) {
            message = '请求方法错误'
        } else if (message.includes('401')) {
            message = '身份验证错误'
        }
        Message({
            message: message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
